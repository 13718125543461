/* import the necessary Bootstrap files */

// overwrite theme variables
// $primary: #004b91;
// $warning: #f8991d;
// $info: #3FA592;

.theme-navbar {
    background-color: #fff;
    color: #000;

    .nav-link {
        color: #000;
    }

    .nav-link:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #43271cf2;
        border-radius: 2px;
    }
}

.theme-footer {
    background-color: #191413;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #fff;
        background-color: #43271cf2;
    }
}

.brand-logo {
    height: 100px;
    width: auto;
}

.imgFounder{
    height: 450px;
    width: auto;
}

// SWIPPER STYLES
.swiper-pagination-bullet-active {
    background-color: #623667 !important;
  }
  .swiper-button-prev {
    color: #623667 !important;
  }
  .swiper-button-next {
    color: #623667 !important;
  }
  .swiper-wrapper{
      height: 94% !important; 
  }

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"